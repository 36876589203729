import React from "react"
import styled from "@emotion/styled"

import {
  Headline,
  Headline2,
  breakpoints,
  colours,
  horizontal,
  vertical,
  typographyStyles,
} from "design-kit"

import { Timeline, Step, Side } from "../../shared-components/Timeline"

import stepOne from "./assets/steps/step-one.svg"
import stepTwo from "./assets/steps/step-two.svg"
import stepThree from "./assets/steps/step-three.svg"
import stepFour from "./assets/steps/step-four.svg"
import stepFive from "./assets/steps/step-five.svg"
import dash from "./assets/dash-blue.svg"

const Section = styled.section`
  background-color: ${colours.greyScale.grey25};
  color: ${colours.offBlack};
  position: relative;
  z-index: 0;
  padding: ${vertical.xl} ${horizontal.s};

  ${breakpoints.tablet`
  text-align: center;
  padding: ${vertical.xxl} initial;
  `}
`

const H2 = styled(Headline2)`
  margin-bottom: ${vertical.xs};

  ${breakpoints.desktop`
    margin-bottom: ${vertical.m};
  `}
`

const TimelineContainer = styled.div`
  position: relative;
  padding-top: ${vertical.m};

  ${breakpoints.tablet`
    padding-top: ${vertical.l};
  `}
`

const StepTitle = styled.div`
  ${typographyStyles.body}
  color: ${colours.offBlack};
  padding-top: ${vertical.xxs};
  padding-bottom: ${vertical.xxs};

  ${breakpoints.tablet`
    max-width: 90%;
  `}

  ${breakpoints.desktop`
    max-width: 327px;
  `}
`

const StepText = styled.div`
  ${typographyStyles.body}
  color: ${colours.greyScale.grey100};
  padding-bottom: ${vertical.s};

  ${breakpoints.tablet`
    max-width: 75%;
  `}

  ${breakpoints.desktop`
    max-width: 364px;
  `}
`

const StepIcon = styled.img`
  width: 48px;
  height: 48px;
`

const StepsLine = styled.div`
  position: relative;
  background-size: contain;
  background-repeat: repeat;
  background-image: url("${dash}");
  width: 3px;
  top: 2%;
  height: 87%;
  z-index: -1;

  ${breakpoints.tablet`
    top: 10px;
    height: 85%;
  `}

  ${breakpoints.desktop`
    height: 87%;
  `}
`

const Journey: React.FC = () => (
  <Section>
    <H2>Your home-buying journey:</H2>
    <Headline>What’s next & how Habito can help</Headline>
    <TimelineContainer>
      <Timeline renderLine={StepsLine}>
        <Step
          side={Side.Right}
          paddingTablet={56}
          paddingDesktop={72}
          renderIcon={() => <StepIcon alt="Step 1" src={stepOne} />}
        >
          <StepTitle>Get your mortgage in principle</StepTitle>
          <StepText>
            Use this to show estate agents you’re a serious buyer.
          </StepText>
        </Step>

        <Step
          side={Side.Left}
          paddingTablet={56}
          paddingDesktop={72}
          renderIcon={() => <StepIcon alt="Step 2" src={stepTwo} />}
        >
          <StepTitle>Find a property you like and make an offer</StepTitle>
          <StepText>
            When it’s time to buy, you’ll have to make an offer and negotiate on
            the price.
          </StepText>
        </Step>

        <Step
          side={Side.Right}
          paddingTablet={56}
          paddingDesktop={72}
          renderIcon={() => <StepIcon alt="Step 3" src={stepThree} />}
        >
          <StepTitle>Find a mortgage</StepTitle>
          <StepText>
            We’ll find you the best deal out thousands of mortgages from over 90
            lenders.
          </StepText>
        </Step>

        <Step
          side={Side.Left}
          paddingTablet={56}
          paddingDesktop={72}
          renderIcon={() => <StepIcon alt="Step 4" src={stepFour} />}
        >
          <StepTitle>Do the legal work & buying admin</StepTitle>
          <StepText>
            You’ll need to hire a solicitor or conveyancer before you buy. Their
            job is to do all the legal work you need to buy.
          </StepText>
        </Step>

        <Step
          side={Side.Right}
          paddingTablet={56}
          paddingDesktop={72}
          renderIcon={() => <StepIcon alt="Step 5" src={stepFive} />}
        >
          <StepTitle>Exchange, complete & get the keys</StepTitle>
          <StepText>It's time to move in!</StepText>
        </Step>
      </Timeline>
    </TimelineContainer>
  </Section>
)

export default Journey
