import { FunctionComponent } from "react"
import styled from "@emotion/styled"

import {
  breakpoints,
  Headline2,
  horizontal,
  PrimaryLink,
  vertical,
} from "design-kit"

const Section = styled.section`
  text-align: center;
  padding: ${vertical.xl} ${horizontal.s};

  ${breakpoints.tablet`
    padding: initial ${horizontal.l};
  `}

  ${breakpoints.desktop`
    padding: ${vertical.xxl} 0;
  `}
`

const H2 = styled(Headline2)`
  margin-bottom: ${vertical.m};

  ${breakpoints.desktop`
    margin-bottom: ${vertical.l};
  `}
`

const GetStarted: FunctionComponent<{
  isLoggedIn: boolean
  registerButtonClick: (buttonName: string) => void
}> = ({ isLoggedIn, registerButtonClick }) => {
  const hrefUrl = isLoggedIn ? "/mip" : "/sign-up?referrer=mip"

  return (
    <Section>
      <H2>Get your mortgage in principle in just a few minutes</H2>
      <PrimaryLink
        href={hrefUrl}
        onClick={() => registerButtonClick("get_started_mip")}
        width={{
          mobile: "full-width",
          tablet: "content-width",
          desktop: "content-width",
        }}
        text="Get started"
        descriptiveText="Get started with an MIP"
      />
    </Section>
  )
}

export default GetStarted
