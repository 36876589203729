// TODO needs to be updated when design is created and problably should be moved to design-kit
// we should also think about unifying all tables (like the one that lives in marketing/src/views/StampDutyCalculator/Common.tsx)

import styled from "@emotion/styled"
import { typographyStyles, vertical, colours, horizontal } from "design-kit"

const Table = styled.table`
  border-collapse: collapse;
  ${typographyStyles.body};
  text-align: left;
  margin-bottom: ${vertical.xs};
`

const TableCaption = styled.caption`
  ${typographyStyles.bodyBold};
  text-align: left;
  margin-bottom: ${vertical.xxs};
`

const TableHeadEmpty = styled.th`
  background: none;
  border: none;
`

const TableHead = styled.th`
  background: ${colours.white};
  border: 1px solid ${colours.greyScale.grey75};
  padding: ${vertical.xxs} ${horizontal.xs};
`

const TableData = styled.td`
  border: 1px solid ${colours.greyScale.grey75};
  padding: ${vertical.xxs} ${horizontal.xs};
`

export { Table, TableCaption, TableHeadEmpty, TableHead, TableData }
