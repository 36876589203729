import React from "react"
import styled from "@emotion/styled"

import {
  colours,
  breakpoints,
  typographyStyles,
  GridRow,
  column,
  Body,
  Headline2,
  vertical,
  horizontal,
} from "design-kit"
import { SofaIcon, IllusionIcon, WalletIcon } from "./assets/SvgIcons"

const Section = styled.section`
  color: ${colours.offBlack};
  background-color: ${colours.greyScale.grey25};
  padding: ${vertical.xl} ${horizontal.s};

  ${breakpoints.tablet`
    padding: initial ${horizontal.l};
  `}

  ${breakpoints.desktop`
  text-align: center;
  padding: ${vertical.xxl} 0;
  `}
`

const ContentContainer = styled.div`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}
`

const H2 = styled(Headline2)`
  text-align: center;
  margin-bottom: ${vertical.xs};

  ${breakpoints.desktop`
    margin-bottom: ${vertical.l};
  `}
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoints.desktop`
  flex-direction: row;
  `}
`

const Article = styled.article`
  text-align: center;
  padding: ${vertical.xs} ${horizontal.s};

  ${breakpoints.tablet`
  width: 50%;
  margin: 0 auto;
  `}

  ${breakpoints.desktop`
  width: calc(100%/3);
  `}
`

const H3 = styled.h3`
  ${typographyStyles.headlineBold};
  margin-top: ${vertical.xs};
  margin-bottom: ${vertical.xxs};

  ${breakpoints.desktop`
  margin-top: ${vertical.s};
  margin-bottom: ${vertical.xs};
  `}
`

const HabitoCanSortYou: React.FC = () => (
  <Section>
    <GridRow>
      <ContentContainer>
        <H2>Habito can sort your MIP & more</H2>
        <FlexContainer>
          <Article>
            <SofaIcon />
            <H3>Get a mortgage online for FREE</H3>
            <Body>
              When you’re ready, Habito’s friendly experts can help you sort
              your mortgage online. And all for free.
            </Body>
          </Article>
          <Article>
            <IllusionIcon />
            <H3>100% impartial advice</H3>
            <Body>
              We’re independent – not tied to any particular lender. That means
              we’re free to explore the whole market to find your perfect
              mortgage and give you unbiased advice.
            </Body>
          </Article>
          <Article>
            <WalletIcon />
            <H3>Exclusive mortgage deals</H3>
            <Body>
              We get access to mortgages you won’t find anywhere else – not even
              direct from lenders. That’s right, you’re in the inner circle now.
            </Body>
          </Article>
        </FlexContainer>
      </ContentContainer>
    </GridRow>
  </Section>
)

export default HabitoCanSortYou
