import React from "react"
import styled from "@emotion/styled"

import { WrappedBase } from "../shared-components/Shared/WrappedBase"
import { UserContext } from "../shared-components/Shared/UserProvider"
import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"
import Header from "../views/MIP/Header"
import Overview from "../views/MIP/Overview"
import Journey from "../views/MIP/Journey"
import HabitoCanSortYou from "../views/MIP/HabitoCanSortYou"
import GetStarted from "../views/MIP/GetStarted"
import FAQ from "../views/MIP/FAQ"

import config from "../../config.json"
import { registerButtonClick } from "../utils/amplitude"

const pageName = "mortgage_in_principle"

const BorderBoxContainer = styled.div`
  * {
    box-sizing: border-box;
  }
`

const MIPLandingPage: React.FC = () => (
  <WrappedBase
    metaTitle="Get a mortgage in principle online, free! | Habito"
    metaDescription="Show sellers you're serious with an MIP. It's totally free, no credit check needed. Get yours right now."
    canonicalUrl="https://www.habito.com/mortgage-in-principle"
    noIndex={false}
    pageName={pageName}
    intercom={false}
    config={config}
    head={null}
  >
    <MIPContent />
  </WrappedBase>
)

const MIPContent: React.FC = () => (
  <UserContext.Consumer>
    {user => (
      <BorderBoxContainer>
        <Navbar theme="transparent" />

        <main id="main-content">
          <Header
            isLoggedIn={Boolean(user)}
            registerButtonClick={registerButtonClick(pageName)}
          />
          <Overview
            isLoggedIn={Boolean(user)}
            registerButtonClick={registerButtonClick(pageName)}
          />
          <Journey />
          <FAQ />
          <HabitoCanSortYou />
          <GetStarted
            isLoggedIn={Boolean(user)}
            registerButtonClick={registerButtonClick(pageName)}
          />
        </main>

        <Footer />
      </BorderBoxContainer>
    )}
  </UserContext.Consumer>
)

export default MIPLandingPage
