import { FunctionComponent } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import {
  breakpoints,
  colours,
  GridRow,
  column,
  Headline,
  PrimaryLink,
  Statement1,
  vertical,
} from "design-kit"

const HeaderImageContainer = styled.div`
  color: ${colours.offBlack};
  width: 100%;
  background: linear-gradient(
    135deg,
    ${colours.gradientBase.seafoam} 0%,
    ${colours.gradientBase.electricGrape} 100%
  );
`

const ContentContainer = styled.div`
  padding: calc(${vertical.xl} + ${vertical.xl}) 0 ${vertical.xl};
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 10,
  })}

  ${breakpoints.desktop`
    padding-top: calc(${vertical.xxl} + ${vertical.xl});
  `}
`

const HeadlineWithMargin = styled(Headline)`
  margin-bottom: ${vertical.l};
  max-width: 752px;

  ${breakpoints.desktop`
    margin-bottom: ${vertical.xl};
  `}
`

const Header: FunctionComponent<{
  isLoggedIn: boolean
  registerButtonClick: (buttonName: string) => void
}> = ({ isLoggedIn, registerButtonClick }) => {
  const hrefUrl = isLoggedIn ? "/mip" : "/sign-up?referrer=mip"

  return (
    <HeaderImageContainer>
      <GridRow>
        <ContentContainer>
          <Statement1
            css={css`
              margin-bottom: ${vertical.xs};
            `}
          >
            Mortgage in principle
          </Statement1>
          <HeadlineWithMargin>
            Answer just a few questions about your income and deposit, then
            download your MIP certificate. There’s no credit check. And it’s
            freeee!
          </HeadlineWithMargin>
          <PrimaryLink
            href={hrefUrl}
            onClick={() => registerButtonClick("get_my_mip")}
            width="content-width"
            text="Get my Mortgage in Principle"
          />
        </ContentContainer>
      </GridRow>
    </HeaderImageContainer>
  )
}

export default Header
