import * as React from "react"
import styled from "@emotion/styled"

import { breakpoints, column, GridRow } from "design-kit"

const Steps = styled.div`
  ${column({
    widthDesktop: 12,
    widthTablet: 6,
    widthMobile: 4,
  })};

  display: flex;
  flex-direction: column;
  position: relative;
`

const TimelineLineContainer = styled.div`
  ${column({
    widthDesktop: 0,
    widthTablet: 0,
    widthMobile: 1,
  })}

  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -2;
  ${breakpoints.tablet`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`
const StepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const StepContainerLeft = styled(StepContainer)`
  flex-direction: row;
`

const StepContainerBottom = styled.div`
  width: 100%;
  display: flex;

  ${breakpoints.tablet`
    flex-direction: column;
  `}
`

const StepIconContainer = styled.div`
  ${column({
    widthMobile: 1,
  })}
  display: flex;
  justify-content: center;

  ${breakpoints.tablet`
    width: 100%;
    position: absolute;
    z-index: -1;
  `}
`
const BottomIconContainer = styled.div`
  ${column({
    widthDesktop: 12,
    widthTablet: 6,
    widthMobile: 1,
  })}
  display: flex;
  justify-content: center;

  ${breakpoints.tablet`
    align-items: center;
    flex-direction: column;
    width: 100%;
    z-index: -1;
  `}
`

const BottomContainer = styled.div`
  ${column({
    widthDesktop: 12,
    widthTablet: 6,
    widthMobile: 3,
  })}
  display: flex;

  ${breakpoints.tablet`
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    z-index: -1;
  `}
`
interface StepBodyProps {
  paddingTablet: number
  paddingDesktop: number
}

const StepBody = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const StepBodyLeft = styled(StepBody)<StepBodyProps>`
  ${column({
    widthDesktop: 6,
    collapseMarginsDesktop: true,

    widthTablet: 3,
    collapseMarginsTablet: true,

    widthMobile: 3,
    offsetMobile: 0,
  })};

  ${props => breakpoints.tablet`
      text-align: right;
      align-items: flex-end;
      padding-right: ${props.paddingTablet}px;
  `}

  ${props => breakpoints.desktop`
      text-align: right;
      align-items: flex-end;
      padding-right: ${props.paddingDesktop}px;
  `}
`

const StepBodyRight = styled(StepBody)<StepBodyProps>`
  ${column({
    widthDesktop: 6,
    offsetDesktop: 6,

    widthTablet: 3,
    offsetTablet: 3,

    widthMobile: 3,
    offsetMobile: 0,
  })};

  ${props => breakpoints.tablet`
      text-align: left;
      padding-left: ${props.paddingTablet}px;
  `}

  ${props => breakpoints.desktop`
      text-align: left;
      padding-left: ${props.paddingDesktop}px;
  `}
`

const StepBodyBottom = styled(StepBody)`
  justify-content: center;
  ${breakpoints.tablet`
      text-align: center;
  `}

  ${breakpoints.desktop`
      text-align: center;
  `}
`

// Babel doesn't support const enums
export enum Side {
  Left,
  Right,
  Bottom,
}

export type StepProps = {
  side: Side
  renderIcon: React.FunctionComponent
} & StepBodyProps

export const Step: React.FunctionComponent<StepProps> = ({
  side,
  renderIcon: Icon,
  children,
  paddingTablet,
  paddingDesktop,
}) =>
  (() => {
    switch (side) {
      case Side.Left:
        return (
          <StepContainerLeft>
            <StepIconContainer>
              <Icon />
            </StepIconContainer>
            <StepBodyLeft
              paddingTablet={paddingTablet}
              paddingDesktop={paddingDesktop}
            >
              {children}
            </StepBodyLeft>
          </StepContainerLeft>
        )
      case Side.Right:
        return (
          <StepContainer>
            <StepIconContainer>
              <Icon />
            </StepIconContainer>
            <StepBodyRight
              paddingTablet={paddingTablet}
              paddingDesktop={paddingDesktop}
            >
              {children}
            </StepBodyRight>
          </StepContainer>
        )
      case Side.Bottom:
        return (
          <StepContainerBottom>
            <BottomIconContainer>
              <Icon />
            </BottomIconContainer>
            <BottomContainer>
              <StepBodyBottom>{children}</StepBodyBottom>
            </BottomContainer>
          </StepContainerBottom>
        )
    }
  })()

interface TimelineProps {
  renderLine: React.FunctionComponent
}

export const Timeline: React.FunctionComponent<TimelineProps> = ({
  renderLine: TimelineLine,
  children,
}) => (
  <GridRow>
    <Steps>
      <TimelineLineContainer>
        <TimelineLine />
      </TimelineLineContainer>
      {children}
    </Steps>
  </GridRow>
)
