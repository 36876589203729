import { FunctionComponent } from "react"
import styled from "@emotion/styled"

import {
  Body,
  Headline2,
  GridRow,
  TextLink,
  breakpoints,
  column,
  horizontal,
  vertical,
} from "design-kit"

import mip_example_webp from "./assets/mip-example.webp"
import mip_example_png from "./assets/mip-example.png"

const Section = styled.section`
  ${column({
    widthMobile: 4,
    widthTablet: 6,
    widthDesktop: 12,
  })}
  padding-top: ${vertical.xl};

  ${breakpoints.tablet`
    display: flex;
    padding-top: ${vertical.xl};
    align-items: center;
  `}

  ${breakpoints.desktop`
  align-items: flex-end;
  `}
`

const H2 = styled(Headline2)`
  margin-bottom: ${vertical.xs};

  ${breakpoints.desktop`
    margin-bottom: ${vertical.m};
  `}
`

const BodyWithBottomMargin = styled(Body)`
  margin-bottom: ${vertical.xs};

  &:last-of-type {
    margin-bottom: ${vertical.m};
  }

  ${breakpoints.tablet`
    &:last-of-type {
      margin-bottom: ${vertical.xxl};
    }
  `}

  ${breakpoints.desktop`
    margin-bottom: ${vertical.m};
  `}
`

const Img = styled.img`
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: ${vertical.s};

  ${breakpoints.tablet`
    margin-left: ${horizontal.s};
  `};
`

const Overview: FunctionComponent<{
  isLoggedIn: boolean
  registerButtonClick: (buttonName: string) => void
}> = ({ isLoggedIn, registerButtonClick }) => {
  const hrefUrl = isLoggedIn ? "/mip" : "/sign-up?referrer=mip"

  return (
    <GridRow>
      <Section>
        <div>
          <H2>Show sellers you're serious</H2>
          <BodyWithBottomMargin>
            A mortgage in principle (MIP) is a certificate showing what you can
            borrow. It shows estate agents and sellers that you’re serious about
            buying, and in a position to do it.
          </BodyWithBottomMargin>
          <BodyWithBottomMargin>
            An MIP adds more credibility to any offers you make, too. That’s
            because it reassures sellers that you can actually afford the offer
            you’re making.
          </BodyWithBottomMargin>
          <BodyWithBottomMargin>
            Want one? You’re in the right place.{" "}
            <TextLink
              text="Get your MIP in just minutes"
              href={hrefUrl}
              onClick={() => registerButtonClick("get_my_mip_minutes")}
            />
            , for free. No credit check needed.
          </BodyWithBottomMargin>
        </div>

        <picture>
          <source srcSet={mip_example_webp} type="image/webp" />
          <Img
            src={mip_example_png}
            alt="An example of a Habito Mortgage in Principle document with a date, the customer name and the estimated amount they can borrow (£350000)."
          />
        </picture>
      </Section>
    </GridRow>
  )
}

export default Overview
